import { createRouter, createWebHistory, RouteRecordRaw, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router";
import { useAppStore } from "@/store/AppStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "login" */ "../views/HomeView.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "login" */ "../views/RegisterView.vue"),
  },
  {
    path: "/result",
    name: "Result",
    component: () => import(/* webpackChunkName: "login" */ "../views/ResultView.vue"),
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () => import("../views/condition/ImprintView.vue"),
  },
  {
    path: "/terms-of-use",
    name: "TermOfUse",
    component: () => import("../views/condition/TermOfUseView.vue"),
  },
  {
    path: "/data-protection-statement",
    name: "DataProtectionStatement",
    component: () => import("../views/condition/DataProtectionStatementView.vue"),
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("../views/condition/CookiesView.vue"),
  },
  {
    path: "/note-for-us-residents",
    name: "NoteForUSResidents",
    component: () => import("../views/condition/NoteForUSResidentsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const pages: string[] = ["Register", "Result"];
  const appStore = useAppStore();

  if (typeof to.name === "string" && pages.includes(to.name) && appStore.type === "") {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;

import { defineStore } from "pinia";
import { Salon, Shop } from "@/types/models";

export const useAppStore = defineStore("app", {
  state: () => ({
    type: "" as string,
    formData: {} as Salon | Shop,
    registerStatus: "" as string,
  }),
  actions: {
    setType(type: string) {
      this.type = type;
    },

    setFormData(formData: Salon | Shop) {
      this.formData = formData;
    },

    setRegisterStatus(status: string) {
      this.registerStatus = status;
    },
  },
});
